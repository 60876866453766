const debounceMap = new Map();

export const debounce = (func, delay) => {
  return function () {
    const context = this;
    const args = arguments;

    if (debounceMap.has(func)) clearTimeout(debounceMap.get(func));

    const debounceTimer = setTimeout(() => {
      func.apply(context, args);
      debounceMap.delete(func); // Clean up the map once the function is executed
    }, delay);

    debounceMap.set(func, debounceTimer);
  };
};

export const debounceWithThrottle = (func, debounceDelay, throttleInterval) => {
  return function (...args) {
    const currentTime = Date.now();

    if (!debounceMap.has(func)) {
      debounceMap.set(func, {
        debounceTimer: null,
        lastInvokeTime: 0,
      });
    }

    const { debounceTimer, lastInvokeTime } = debounceMap.get(func);
    const timeSinceLastInvoke = currentTime - lastInvokeTime;

    if (timeSinceLastInvoke >= throttleInterval) {
      clearTimeout(debounceTimer);
      debounceMap.set(func, {
        debounceTimer: null,
        lastInvokeTime: currentTime,
      });
      func.apply(this, args);
    } else {
      clearTimeout(debounceTimer);
      const newDebounceTimer = setTimeout(() => {
        const latestTimeSinceLastInvoke = Date.now() - lastInvokeTime;
        if (latestTimeSinceLastInvoke >= throttleInterval) {
          debounceMap.set(func, {
            debounceTimer: null,
            lastInvokeTime: Date.now(),
          });
          func.apply(this, args);
        }
      }, debounceDelay);

      debounceMap.set(func, {
        debounceTimer: newDebounceTimer,
        lastInvokeTime,
      });
    }
  };
};
